<template>
   <div class="devices">
    <base-section id="theme-features">
      <base-section-heading title="Dispositivos">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
            </v-col>
            <v-col>
              <add-device :types="types" 
                          :userList="users"
                          :iconList="iconList" 
                          :editItem="deviceEditItem" 
                          :openDialog="editDialog" 
                          @close="editDialog = false"
                          @saved="reloadTable = true" />
            </v-col>
          </v-row>
          <v-row>
            <commands :commandList='commands' :dialog='commandDialog' @close="commandDialog = false" :deviceNew="commandDevice" :types="types" />
          </v-row>
        </v-container>
        </base-section-heading>
        <v-container >
          <v-row>
              <device-table :userList="users" :typeList="types" @editItem='editDevice' :reload="reloadTable" @reloaded="hasReloaded" @editCommand='addCommand' />
          </v-row>
        </v-container>
    </base-section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  metaInfo: { title: 'Device List' },
  components: { 
    deviceTable: () => import('./sections/devices/deviceTable'),
    addDevice: () => import('./sections/devices/addDeviceAdmin'),
    commands: () => import('./sections/devices/commands')
  },
  data:()=>({
    types:[],
    users:[],
    deviceEditItem:{},
    iconList:[{
            image:'icons/about.jpg',
            name: 'image 1'
            },
            {
            image:'icons/logo.svg',
            name:'image 2'
            },{
            image:'images/truck01.png',
            name:'Truck 01'
            },
        ],
    commands:[],
    commandDialog:false,
    commandDevice:{},
    editDialog:false,
    reloadTable:false,
  }),
  mounted(){
      this.loadData();
  },
  methods:{
      ...mapActions(['getDeviceTypes','getUserList','getCommands']),
      loadData(){
            this.getDeviceTypes().then((res)=>{
                this.types = res.data.data;
                //console.log(this.types);
            }).catch((err)=>{
                console.log(err);
            });

            this.getUserList().then((res)=>{
              this.users = res.data.data;
              //console.log(this.users);
            }).catch((err)=>{
              console.log(err);
            });

            this.getCommands().then((res)=>{
              this.commands = res.data.data;
            }).catch((err)=>{
              console.log(err);
            });

        },
      editDevice(item){
        this.deviceEditItem = item;
        this.editDialog = true;
        //console.log(this.deviceEditItem);
      }
      ,hasReloaded(){
        this.reloadTable = false;
      }
      ,addCommand(item){
        //console.log(item);
        this.commandDevice = item;
        this.commandDialog = true;
      }
  }
}
</script>

<style>

</style>